@import '../../styles/variables.css';

.Container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gap-mobile);
  padding-right: var(--gap-mobile);

  @media (--tabletAndAbove) {
    max-width: env(--tablet-768);
    padding-left: var(--gap-tablet);
    padding-right: var(--gap-tablet);
  }
  @media (--tabletLgAndAbove) {
    max-width: env(--tablet-1024);
    padding-left: var(--gap-desktop);
    padding-right: var(--gap-desktop);
  }
  @media (--laptopAndAbove) {
    max-width: env(--laptop-1280);
  }
  @media (--laptopLgAndAbove) {
  }
  @media (--desktopAndAbove) {
  }
}

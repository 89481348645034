@import '../../styles/variables.css';

.MainAndAside {
  align-items: center;
  padding-top: var(--gap-mobile);

  @media (--tabletAndAbove) {
    padding-top: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    padding-top: var(--gap-desktop);
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
}

.Main {
  width: 100%;
  padding-bottom: var(--gap-mobile);

  @media (--tabletAndAbove) {
    padding-bottom: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    width: 66%;
    padding-bottom: 0;
    padding-right: var(--gap-desktop);
  }
}

.Aside {
  border-color: var(--color-primary);
  border-style: solid;
  border-width: 0;
  border-top-width: 3px;
  width: 100%;
  padding-top: var(--gap-mobile);

  @media (--tabletAndAbove) {
    padding-top: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    width: 34%;
    border-top-width: 0;
    border-left-width: 3px;
    padding-top: 0;
    padding-left: var(--gap-desktop);
  }
}

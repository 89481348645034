.flexWrap {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.flexNoWrap {
  display: flex;
  flex-wrap: nowrap;
  align-content: space-between;
}

.flexRow {
  flex-direction: row;
}

.flexCol {
  flex-direction: column;
}

.contentRight {
  justify-content: flex-end;
}

.contentRight {
  justify-content: flex-end;
}

.contentCenter {
  justify-content: center;
}

.contentCenter {
  justify-content: center;
}

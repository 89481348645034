@import '../../styles/variables.css';

.TextAndImage {
  align-items: center;

  @media (--tabletLgAndAbove) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.Text {
  padding-top: var(--gap-mobile);

  @media (--tabletAndAbove) {
    padding-top: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    padding-top: var(--gap-desktop);
  }
}

.Image {
  opacity: 0.9;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

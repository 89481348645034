@import '../../styles/variables.css';

@keyframes accordion {
  from {
    height: 0px;
  }
  to {
    height: var(--height, auto);
  }
}

@keyframes accordionCollapse {
  from {
    height: var(--height, auto);
  }
  to {
    height: 0px;
  }
}

.Accordion {
  margin-top: var(--gap-general);

  &:first-of-type {
    margin-top: 0;
  }
}

.Summary {
  justify-content: space-between;
  align-items: flex-start;
  color: var(--color-primary);
  cursor: pointer;
  font-size: 2.4rem;
  font-weight: 700;

  & img {
    width: 2rem;
    padding: 0.5rem 0;
    margin-left: var(--gap-general);
    transition: transform var(--animationLength) ease-in-out;

    &[data-active] {
      transform: rotate(180deg);
    }
  }
}

.Body {
  overflow-y: hidden;
  height: 0px;
  animation: accordionCollapse var(--animationLength) forwards;

  &[data-active] {
    animation: accordion var(--animationLength) forwards;
    height: auto;
  }

  & p:first-of-type {
    padding-top: 1rem;
  }
}

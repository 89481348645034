@import '../../styles/variables.css';

.Newsletter {
  padding-top: var(--gap-general);

  & h3 {
    font-size: 2.4rem;
  }

  & input[type='email'] {
    background: #27405214;
    border: none;
    padding: 1rem;
    width: 100%;
  }

  & input[type='checkbox'] {
    margin-right: 1rem;
  }
}

.Checkbox {
  font-size: 1rem;

  & > * {
    display: inline-block;
  }
}

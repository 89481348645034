@import '../../styles/variables.css';

.List {
  border-bottom: 2px solid var(--color-formBorder);
  padding-bottom: var(--gap-general);

  & h4 {
    padding-top: var(--gap-general);
    padding-bottom: 0.5rem;
  }

  & li {
    list-style: none;
  }
}

.MasculineText {
  color: var(--color-masculine);
  font-weight: 700;
}

.FeminineText {
  color: var(--color-feminine);
  font-weight: 700;
}

@import '../../styles/variables.css';

.Footer {
  color: #fff;
  background-color: #353538;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIYAAACGCAYAAAAYefKRAAAACXBIWXMAAC4jAAAuIwF4pT92AAACE0lEQVR4nO3ay21UQRBA0TYiALLwEkIgZEIgFDIZhDwShrljz+eN5Nd9jlSbWlVLd9lPh8NhwP8+nWxY3hAG5wiDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMEjCIAmDJAySMB7o+fn5y5/Z4+3CeJBjED/HGN/2eL8wHuBVFF/3+gZhbGyGKIYwtjVLFEMY25kpiiGMbcwWxRDG/WaMYgjjPrNGMYRxu5mjGMK4zexRDGFcb4UohjCus0oUQxiXWymKIYzLrBbFEMb7VoxiCONtq0YxhHHeylEMYbTVoxjCOCWKF8J4RRR/CeNIFP8ShijS8mGIoi0dhijOWzYMUbxtyTBE8b7lwhDFZZYKQxSXWyYMUVxniTBEcb3pwxDFbaYOQxS3mzYMUdxnyjBEcb/pwhDFNqYKQxTbmSYMUWxrijBEsb3dhyGKx9h1GKJ4nN2GIYrH+rzj27+PMX4c5yP79cHvS0+Hw6H2LG75z8A0YZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRgkYZCEQRIGSRicGmP8BtTjRia4fyMyAAAAAElFTkSuQmCC');
  background-size: 30px auto;
  background-position: center top;
  margin-top: var(--gap-mobile);
  padding-top: var(--gap-mobile);
  padding-bottom: var(--gap-mobile);

  @media (--tabletAndAbove) {
    margin-top: var(--gap-tablet);
    padding-top: var(--gap-tablet);
    padding-bottom: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    margin-top: var(--gap-desktop);
    padding-top: var(--gap-desktop);
    padding-bottom: var(--gap-desktop);
  }
}

.withHeight {
  @media (--tabletLgAndAbove) {
    height: 250px;
  }
}

.Logo {
  width: 100%;

  @media (--tabletLgAndAbove) {
    justify-content: flex-start;
    width: 34%;
    order: 1;
  }
}

.Social {
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (--tabletLgAndAbove) {
    justify-content: flex-end;
    align-items: center;
    width: 34%;
    padding-top: 0;
    padding-bottom: 0;
    order: 4;
  }

  & a {
    margin: 0 0 0 3rem;
    padding: 0;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.Awards {
  align-items: center;
  width: 100%;
  padding-bottom: 3rem;

  @media (--tabletLgAndAbove) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 66%;
    padding-bottom: 0;
    order: 2;
  }

  & img {
    width: 100%;
    max-width: 125px;
    margin-bottom: var(--gap-mobile);

    @media (--tabletLgAndAbove) {
      margin-bottom: 0;
      margin-left: var(--gap-desktop);
    }

    &.rar {
      @media (--tabletLgAndAbove) {
        max-width: 81px;
      }
    }

    &.ebma {
      @media (--tabletLgAndAbove) {
        max-width: 134px;
      }
    }

    &.inhouse {
      @media (--tabletLgAndAbove) {
        max-width: 116px;
      }
    }
  }
}

.Links {
  flex-wrap: wrap;
  width: 100%;

  @media (--tabletLgAndAbove) {
    justify-content: flex-start;
    align-items: center;
    width: 66%;
    padding-bottom: 0;
    order: 3;
  }

  & a {
    padding: 0 1rem;
    border-left: 3px solid #fff;
    text-align: center;

    &:first-of-type {
      padding-left: 0;
      border-left: none;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

.FooterBase {
  background-color: #dddde3;
  font-weight: 600;
  font-size: 1.4rem;
  padding-top: var(--gap-general);
  padding-bottom: var(--gap-general);
  letter-spacing: normal;
}

.FooterBaseAlign {
  flex-wrap: wrap;

  @media (--tabletAndAbove) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.Copyright {
  text-align: center;
  padding-bottom: var(--gap-general);

  @media (--tabletAndAbove) {
    text-align: left;
    padding-bottom: 0;
  }
}


.Terms {
  flex-wrap: wrap;
  width: 100%;

  @media (--tabletAndAbove) {
    justify-content: flex-end;
    align-items: center;
    min-width: 465px;
    width: 465px;
    padding-bottom: 0;
  }

  & a {
    padding: 0 1rem;
    border-left: 2px solid #333;
    text-align: center;

    &:first-of-type {
      padding-left: 0;
      border-left: none;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

@import '../../styles/variables.css';

.Copy {
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
  border-radius: 0.8rem;
  border-color: var(--color-formBorder);
  border-width: 1px;
  border-style: solid;
  flex-grow: 1;
  min-height: 40rem;
  padding: 1rem;

  @media (--tabletLgAndAbove) {
    min-height: 60rem;
  }
}

@import '../../styles/variables.css';

@keyframes donut {
  to {
    stroke-dashoffset: var(--offset);
  }
}

.Donut {
  --offset: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);

  & circle {
    stroke-dasharray: 100; /* this value is the pixel circumference of the circle */
    stroke-dashoffset: 100;
    animation: donut var(--animationLength) ease-out forwards;
  }
}

.Results {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 50%;
  margin-top: -4rem;
  font-size: 2rem;
  text-align: center;
}

.MasculineLine {
  stroke: var(--color-masculine);
}

.FeminineLine {
  stroke: var(--color-feminine);
}

.MasculineStrong {
  color: var(--color-masculine);
  font-weight: 700;
}

.FeminineStrong {
  color: var(--color-feminine);
  font-weight: 700;
}

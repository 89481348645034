@import '../../styles/variables.css';

.Form {
  & textarea {
    border-radius:  0.8rem;
    flex-grow: 1;
    min-height: 40rem;

    @media (--tabletLgAndAbove) {
      min-height: 60rem;
    }
  }
}

.Group {
  padding-top: var(--gap-mobile);

  @media (--tabletAndAbove) {
    padding-top: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    padding-top: var(--gap-desktop);
  }
}

@import '../../styles/variables.css';

.Header {
  color: #fff;
  background-color: var(--color-primary);
  padding-top: var(--gap-mobile);
  padding-bottom: var(--gap-mobile);

  @media (--tabletAndAbove) {
    padding-top: var(--gap-tablet);
    padding-bottom: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    padding-top: var(--gap-desktop);
    padding-bottom: var(--gap-desktop);
  }

  & h1 {
    display: block;

    & + p {
      font-weight: 700;
    }
  }

  & video {
    width: 100%;
    max-width: 400px;
  }
}

.Image {
  display: none;

  @media (--tabletLgAndAbove) {
    display: block;
  }
}

@import '../../styles/variables.css';

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.singleWidth {
}

.fadeIn {
  opacity: 0.5;
  animation-direction: normal;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  animation-play-state: running;
}
